import React from "react"

const SvgMap = props => (
  <svg width={36} height={36} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18zM9.451 14.94c0-4.715 3.835-8.55 8.549-8.55 4.714 0 8.548 3.835 8.549 8.55 0 1.229-.252 2.403-.747 3.49-2.139 4.677-6.237 9.614-7.442 11.016a.474.474 0 01-.72 0c-1.205-1.402-5.304-6.34-7.441-11.017a8.35 8.35 0 01-.748-3.49zm4.108 0a4.446 4.446 0 004.44 4.44 4.445 4.445 0 004.441-4.44A4.446 4.446 0 0018 10.498a4.446 4.446 0 00-4.441 4.44z"
      fill="#9C997C"
    />
  </svg>
)

export default SvgMap
