import React from 'react'

const SvgPhone = ({ width = 56, ...props }) => (
  <svg width={width} fill="none" viewBox="0 0 56 56" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56 28c0 15.464-12.536 28-28 28S0 43.464 0 28 12.536 0 28 0s28 12.536 28 28zm-15.93 3.951l4.101 4.123c1.197 1.203 1.486 3.006.72 4.486-1.327 2.566-3.686 4.176-6.822 4.658-.655.1-1.331.15-2.023.15-5.491 0-12.01-3.102-17.17-8.294-5.81-5.845-8.962-13.395-8.028-19.235.501-3.132 2.126-5.481 4.7-6.793 1.484-.756 3.285-.456 4.481.747l4.102 4.123a3.884 3.884 0 011.119 3.107 3.82 3.82 0 01-1.645 2.801c-.028.02-.058.038-.087.055l-1.14.663c.41 1.544 1.495 4.74 3.906 7.165 2.427 2.442 5.608 3.54 7.146 3.956l.674-1.137a1.228 1.228 0 01.054-.085 3.82 3.82 0 012.811-1.627 3.881 3.881 0 013.1 1.137z"
      fill="#F29EBA"
    />
  </svg>
)

export default SvgPhone
