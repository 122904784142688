import React from 'react'

const SvgPhone = ({ width = 36, ...props }) => (
  <svg width={width} fill="none" {...props} viewBox="0 0 36 36">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18zm-10.241 2.54l2.637 2.65c.77.774.955 1.933.463 2.884-.853 1.65-2.37 2.685-4.386 2.995-.421.064-.856.096-1.3.096-3.53 0-7.721-1.994-11.038-5.331-3.736-3.758-5.761-8.612-5.161-12.366.322-2.014 1.367-3.524 3.02-4.367a2.472 2.472 0 012.882.48l2.637 2.65c.526.53.788 1.258.719 1.998a2.456 2.456 0 01-1.113 1.836l-.733.426c.263.993.961 3.047 2.511 4.607 1.56 1.569 3.605 2.275 4.594 2.542l.433-.73a2.456 2.456 0 011.842-1.1 2.495 2.495 0 011.993.73z"
      fill="#9C997C"
    />
  </svg>
)

export default SvgPhone
