import React from "react"

const SvgWeb = props => (
  <svg width={36} height={36} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18zM18.733 7.025c5.492.36 9.88 4.75 10.24 10.242H29v1.466h-.026a10.996 10.996 0 01-10.24 10.24V29h-1.467v-.026c-5.492-.361-9.881-4.75-10.242-10.24H7v-1.467h.025c.36-5.492 4.75-9.881 10.242-10.242V7h1.466v.025zm-6.572 5.842c.428-1.347 1.017-2.527 1.725-3.469a9.582 9.582 0 00-3.922 3.469h2.197zm-.386 1.466H9.197a9.453 9.453 0 00-.702 2.934h2.92c.04-1.023.164-2.007.36-2.934zm-.36 4.4h-2.92a9.45 9.45 0 00.702 2.934h2.579a17.42 17.42 0 01-.361-2.934zm.745 4.4H9.964a9.574 9.574 0 003.922 3.47c-.71-.943-1.298-2.123-1.726-3.47zm1.513 0c.788 2.284 2.086 3.903 3.594 4.306v-4.306h-3.594zm-.412-1.466h4.006v-2.934h-4.385a16.38 16.38 0 00.38 2.934zm-.38-4.4h4.386v-2.934H13.26a16.39 16.39 0 00-.38 2.934zm.792-4.4h3.594V8.562c-1.508.402-2.806 2.021-3.594 4.305zm10.169 0h2.194a9.592 9.592 0 00-3.922-3.469c.711.942 1.298 2.122 1.728 3.469zm-1.515 0c-.788-2.284-2.087-3.903-3.594-4.305v4.305h3.594zm.41 1.466h-4.004v2.934h4.386a16.516 16.516 0 00-.382-2.934zm.382 4.4h-4.386v2.934h4.004c.209-.914.34-1.901.382-2.934zm-4.386 4.4v4.306c1.507-.403 2.806-2.022 3.594-4.306h-3.594zm5.109 0c-.43 1.347-1.02 2.526-1.728 3.47a9.584 9.584 0 003.922-3.47h-2.194zm.383-1.466h2.578c.381-.914.625-1.901.701-2.934h-2.919a17.43 17.43 0 01-.36 2.934zm0-7.334c.196.927.32 1.911.36 2.934h2.92a9.454 9.454 0 00-.702-2.934h-2.578z"
      fill="#9C997C"
    />
  </svg>
)

export default SvgWeb
