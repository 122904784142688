import React, { useEffect, useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import useMedia from 'use-media'
import Layout from '../components/layout'
import { TitleSection, VHContainer } from '../components/sections'
import SEO from '../components/seo'
import useOnScreen from '../hooks/useOnScreen'
import { colors } from '../components/theme'
import LargeFacebookIcon from '../icons/FacebookLarge'
import LargePhoneIcon from '../icons/PhoneLarge'
import LargeMailIcon from '../icons/MailLarge'
import WebIcon from '../icons/Web'
import MapIcon from '../icons/Map'
import PhoneIcon from '../icons/Phone'

const MainContactsContainer = styled(VHContainer)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 290px;

    &.social {
      li a {
        margin: 0 1.2rem;
      }
      li a:first-of-type {
        margin-left: 0;
      }
      /* add | to facebook links */
      a:not(:first-of-type):before {
        content: '|';
        position: absolute;
        left: -1.3rem;
      }
    }

    li {
      position: relative;
      list-style: none;
      margin: 0;
      margin-bottom: 1rem;
      padding: 0;
      font-size: 1.3rem;
      color: ${colors.greymid};

      a {
        text-decoration: none;
        color: inherit;
        position: relative;
      }

      svg {
        position: absolute;
        left: -116px;
        top: -14px;
      }
    }
  }

  @media (max-width: 1200px) {
    ul {
      height: auto;
      padding-left: 100px;
      li {
        margin-bottom: 4rem;
        a {
          display: block;
        }
        svg {
          left: -100px;
        }
      }
      &.social {
        /* don't add | to facebook links */
        a:not(:first-of-type):before {
          content: '';
          position: absolute;
          left: -1.3rem;
        }
        li a {
          margin: 0 0 1rem;
        }
      }
    }
    @media (max-width: 650px) {
      ul {
        li svg {
          top: -6px;
          left: -70px;
        }
        padding-left: 70px;
      }
    }
  }
`

const ContactsGrid = styled(VHContainer)`
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  -ms-grid-columns: 1fr 100px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 100px;

  div {
    height: 330px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h3 {
    font-weight: 400;
    font-size: 1.1rem;
    color: #9c997c;
    margin-bottom: 1.4rem;
  }
  h4 {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: rgba(43, 43, 46, 0.5);
    margin: -1rem 0 1.4rem;
  }

  span {
    position: relative;
    display: block;
    padding-left: 64px;
    font-size: 1.1rem;
    color: rgba(24, 24, 28, 0.6);
    margin-bottom: 20px;

    svg {
      position: absolute;
      left: 0;
      top: -4px;
    }
  }

  @media (max-width: 1100px) {
    padding: 0;
    grid-column-gap: 60px;
  }
  @media (max-width: 650px) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    div {
      height: auto;
      margin-bottom: 40px;
    }
    span {
      margin-left: 5%;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & > div:nth-child(2) {
      -ms-grid-column: 2;
    }
    & > div:nth-child(3) {
      -ms-grid-column: 3;
    }
    & > div:nth-child(4) {
      -ms-grid-column: 4;
    }
    & > div:nth-child(5) {
      -ms-grid-column: 5;
    }
    & > div:nth-child(6) {
      -ms-grid-column: 6;
    }
    & > div:nth-child(7) {
      -ms-grid-column: 7;
    }
    & > div:nth-child(8) {
      -ms-grid-column: 8;
    }
    & > div:nth-child(9) {
      -ms-grid-column: 9;
    }
    & > div:nth-child(10) {
      -ms-grid-column: 10;
    }
    & > div:nth-child(11) {
      -ms-grid-column: 11;
    }
    & > div:nth-child(12) {
      -ms-grid-column: 12;
    }
    & > div:nth-child(13) {
      -ms-grid-column: 13;
    }
    & > div:nth-child(14) {
      -ms-grid-column: 14;
    }
  }
`

const KontaktPage = () => {
  const data = useStaticQuery(graphql`
    query {
      doulaSanja: file(relativePath: { eq: "kontakt/doula-sanja.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      doulaSanjaMobile: file(
        relativePath: { eq: "kontakt/doula-sanja-mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const isMobile = useMedia({ maxWidth: 650 })

  const [subsection, setSubsection] = useState()

  const doulaSanjaRef = useRef()
  const doulaSanjaIntersect = useOnScreen(doulaSanjaRef)
  const korisniKontaktiRef = useRef()
  const korisniKontaktiIntersect = useOnScreen(korisniKontaktiRef)

  useEffect(() => {
    if (korisniKontaktiIntersect) setSubsection('Korisni Kontakti')
    if (doulaSanjaIntersect) setSubsection('Doula Sanja')
  }, [korisniKontaktiIntersect, doulaSanjaIntersect])

  return (
    <Layout subsection={subsection}>
      <SEO title="Kontakt" />
      <div ref={doulaSanjaRef}>
        <BackgroundImage
          Tag="section"
          fluid={
            isMobile
              ? data.doulaSanjaMobile.childImageSharp.fluid
              : data.doulaSanja.childImageSharp.fluid
          }
          backgroundColor="#c4c4c4"
        >
          <TitleSection img className="kontakt">
            <div>
              <h2 className="kontakt">
                Veselim se čaroliju trudnoće, poroda i majčinstva podijeliti s
                Vama!
              </h2>
              <p>Doula Sanja Bukač Štritof</p>
            </div>
          </TitleSection>
        </BackgroundImage>
        <MainContactsContainer bg="#fefbfc">
          <ul className="social">
            <li className="facebook">
              <LargeFacebookIcon fill="#f49dba" width={isMobile ? 40 : 56} />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/sanjabukacstritof/"
                rel="noopener noreferrer"
              >
                Doula Sanja Bukač Štritof
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/nasemame3/"
                rel="noopener noreferrer"
              >
                Mamma&amp;Mammi
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/doulaZagreb/"
                rel="noopener noreferrer"
              >
                Doula Zagreb
              </a>
            </li>
            <li className="phone">
              <LargePhoneIcon width={isMobile ? 40 : 56} />
              <a href="tel:+38598782801">+385 98 782 801</a>
            </li>
            <li className="email">
              <LargeMailIcon fill="#f49dba" width={isMobile ? 40 : 56} />
              <a href="mailto:sanjadoula1@gmail.com">sanjadoula1@gmail.com</a>
            </li>
          </ul>
        </MainContactsContainer>
      </div>
      <div ref={korisniKontaktiRef}>
        <TitleSection bg="#9c997c">
          <div>
            <h2>Korisni kontakti</h2>
          </div>
        </TitleSection>
        <VHContainer>
          <ContactsGrid>
            <div>
              <h3>Hrvatski zavod za zdravstveno osiguranje</h3>
              <span>
                <WebIcon />
                <a
                  href="https://www.hzzo-net.hr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.hzzo-net.hr
                </a>
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:08007979">0800 79 79</a>
              </span>
            </div>
            <div>
              <h3>Hrvatski zavod za mirovinsko osiguranje</h3>
              <span>
                <WebIcon />
                <a
                  href="https://www.mirovinsko.hr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.mirovinsko.hr
                </a>
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:0800 63 63 63">0800 63 63 63</a>
              </span>
            </div>
            <div>
              <h3>Ministarstvo financija – Porezna uprava</h3>
              <span>
                <WebIcon />
                <a
                  href="https://www.porezna-uprava.hr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.porezna-uprava.hr
                </a>
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:0800 66 99 33">0800 66 99 33</a>
              </span>
            </div>
            <div>
              <h3>Ministarstvo zdravstva i socijalne skrbi</h3>
              <span>
                <WebIcon />
                <a
                  href="https://www.mzss.hr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.mzss.hr
                </a>
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:0800 200 063">0800 200 063</a>
              </span>
            </div>
            <div>
              <h3>Ministarstvo znanosti, obrazovanja i sporta</h3>
              <span>
                <WebIcon />
                <a
                  href="https://www.mzos.hr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.mzos.hr
                </a>
              </span>
            </div>
            <div>
              <h3>Udruga Roditelji u akciji – RODA</h3>
              <span>
                <WebIcon />
                <a
                  href="https://www.roda.hr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.roda.hr
                </a>
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:016177500">01 61 77 500</a> |{' '}
                <a href="tel:0915863717">091 58 63 717</a>
              </span>
            </div>
            <div>
              <h3>Udruga Korak po korak</h3>
              <h4>
                Maloljetne trudnice i majke mogu dobiti sve potrebne informacije
                i psihosocijalnu potporu
              </h4>
              <span>
                <WebIcon />
                <a
                  href="https://www.udrugaroditeljakpk.hr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.udrugaroditeljakpk.hr
                </a>
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:01 244 20 61">01 244 20 61</a>
              </span>
            </div>
            <div>
              <h3>
                Udruga <i>Idem</i>
              </h3>
              <h4>Za stručnu pomoć djeci s posebnim potrebama</h4>
              <span>
                <WebIcon />
                <a
                  href="https://www.idem.hr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.idem.hr
                </a>
              </span>
            </div>
            <div>
              <h3>
                Udruga <i>Puž</i>
              </h3>
              <h4>Za stručnu pomoć djeci s posebnim potrebama</h4>
              <span>
                <WebIcon />
                <a
                  href="https://www.udrugapuz.hr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.udrugapuz.hr
                </a>
              </span>
            </div>
            <div>
              <h3>Udruga samohranih roditelja Hrvatske</h3>
              <h4>Četvrtkom od 16 do 20 sati</h4>
              <span>
                <MapIcon />
                Ozaljska 93, Zagreb
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:013697025">01 369 70 25</a> |{' '}
                <a href="tel:0915039192">091 503 91 92</a>
              </span>
            </div>
            <div>
              <h3>Udruga samohranih roditelja Nada</h3>
              <h4>Ponedjeljkom od 16 do 19 sati</h4>
              <span>
                <MapIcon />
                Mlinska 38, Osijek
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:031377734">031 377 734</a>
              </span>
            </div>
            <div>
              <h3>Udruga rastavljenih i nesastavljenih roditelja</h3>
              <h4>
                Nije namijenjena isključivo samohranim roditeljima nego i
                samcima koji se osjećaju osamljeno i otuđeno. Usmjerena je
                prvenstveno na pružanje psihološke pomoći.
              </h4>
              <span>
                <MapIcon />
                Mlinovi 36, Zagreb
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:098233760">098 233 760</a>
              </span>
            </div>
            <div>
              <h3>Udruga samohranih roditelja i mladeži</h3>
              <span>
                <MapIcon />
                Domobranska 4, Zagreb
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:0981353552">098 135 35 52</a> |{' '}
                <a href="tel:0918930971">091 893 09 71</a>
              </span>
            </div>
            <div>
              <h3>Udruga za unapređenje kvalitete življenja LET</h3>
              <h4>Edukacijski centar za samohrane roditelje</h4>
              <span>
                <MapIcon />
                Tuškanac 43, Zagreb
              </span>
              <span>
                <WebIcon />
                <a
                  href="https://www.udruga-let.hr"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  www.udruga-let.hr
                </a>
              </span>
              <span>
                <PhoneIcon />
                <a href="tel:014823041">01 482 30 41</a>
              </span>
            </div>
          </ContactsGrid>
        </VHContainer>
      </div>
    </Layout>
  )
}

export default KontaktPage
